/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Box, Image, Skeleton } from '@mantine/core';
import {
  AppShell,
  Navbar,
  useMantineTheme,
  Loader,
  Center,
} from '@mantine/core';
import {
  IconSettingsAutomation,
  IconManualGearbox,
  IconCompass,
  IconProgressBolt,
  IconRobot,
  IconFlag,
  IconTrash,
} from '@tabler/icons-react';

//Images
import logo from '../assets/images/logo/CMYK/logo/png/Juice_Primary_Logo.png';

//config

// Custom Components
import { UserMenu } from '../components/_userMenu';
import NavigationLinks from '../components/NavigationLinks';
import TableView from '../components/DataGrid2';

// Custom Adappters
import TransactionAdapter from '../adapters/transactionAdapter';
import docStatusMap from '../constants/docStatusMap';
import Dashboard from '../components/Dashboard';

// import ItemView from "../components/ItemView";
// import pdfDoc from "../pdf/7fbe4c24-fdfb-4f06-a69b-ba8b52d68233.pdf"
// import export1 from "../export/Electricity-Export.csv"
// import export2 from "../export/Water-Export.csv"

function Layout({ setTheme }) {
  const theme = useMantineTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [view, setView] = useState('');
  const [transactionViews, setTransactionViews] = useState([]);
  const [navbarViewData, setNavbarViewData] = useState([]);
  const [logoIsLoading, setLogoIsLoading] = useState(true);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    getViewDetails();
  }, []);

  useEffect(() => {
    // As we want to show "ManualQ" as the initially selected tab/screen
    // when `navbarViewData` (nav menu items) is loaded.
    loadDefaultQueue();
  }, [navbarViewData]);

  const loadDefaultQueue = () => {
    if (navbarViewData.length) {
      const defaultQueueId = docStatusMap.dashboard;

      fetchData(defaultQueueId);

      // Find the right index of the preferred queue and
      // update the `activeTabIndex` state which is used by `NavigationLinks`
      // component to show selected tab.
      navbarViewData.some((navItem, index) => {
        if (navItem.status && navItem.status === defaultQueueId) {
          setActiveTabIndex(index);
          return true;
        }
        return false;
      });

      setView(defaultQueueId);
    }
  };

  const getViewDetails = async () => {
    const companyId = window.sessionStorage.getItem('companyId');
    let viewDetails = await TransactionAdapter.getTransactionViews(companyId);
    setTransactionViews(viewDetails);

    let navbarData = [];
    for (let index = 0; index < viewDetails.length; index++) {
      const element = viewDetails[index];
      if (
        element.navBarDetails !== null &&
        element.navBarDetails !== undefined
      ) {
        let object = element.navBarDetails;
        object.icon = getIconByView(element.navBarDetails.label);
        object.queueOrder = element.queueOrder;
        navbarData.push(element.navBarDetails);
      }
    }

    const sortedQueue = orderByNumericProperty(navbarData, 'queueOrder');
    setNavbarViewData(sortedQueue);
  };

  function orderByNumericProperty(arr, prop) {
    return arr.sort((a, b) => a[prop] - b[prop]);
  }

  const getIconByView = (viewLabel) => {
    switch (viewLabel.toUpperCase()) {
      case 'MANUAL':
        return IconManualGearbox;
      case 'BATCH':
        return IconSettingsAutomation;
      case 'PROCESSING':
        return IconProgressBolt;
      case 'COMPLETE':
        return IconCompass;
      case 'READY FOR EXPORT':
        return IconProgressBolt;
      case 'ZERO Q':
      case 'SCRAPING':
        return IconRobot;
      case 'BOT PREP':
        return IconRobot;
      case 'SQUASHED':
        return IconTrash;
      case 'FAILURE':
        return IconFlag;
      default:
        return IconManualGearbox;
    }
  };

  const fetchData = async (newView) => {
    if (newView === undefined) {
      newView = view;
    }
    setIsLoading(true);
    const data = {
      status: newView,
    };
    const foundTransactions = await TransactionAdapter.getTransactions(data);

    setData(foundTransactions);
    setIsLoading(false);
  };

  const handleNavLinkClick = (index) => {
    fetchData(navbarViewData[index].status);
    setView(navbarViewData[index].status);
  };

  const setDefaultView = () => {
    window.location.href = '/';
  };

  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[1],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={
        <Navbar
          hiddenBreakpoint="sm"
          hidden={true}
          width={{ sm: 150, lg: 200 }}>
          <Navbar.Section grow mt="md">
            <Skeleton
              height={50}
              mx="auto"
              width="90%"
              radius="md"
              visible={logoIsLoading}>
              <Image
                maw={150}
                mx="auto"
                radius="md"
                src={logo}
                alt="Logo"
                onClick={setDefaultView}
                onLoad={() => setLogoIsLoading(false)}
              />
            </Skeleton>
            <br />
            {navbarViewData.length > 0 && (
              <NavigationLinks
                setView={handleNavLinkClick}
                navbarData={navbarViewData}
                activeTabIndex={activeTabIndex}
              />
            )}

            {/* Shows nav-menu skeleton when loading... */}
            {navbarViewData.length === 0 && (
              <Box mx="sm">
                <Skeleton height={35} mb="sm" />
                <Skeleton height={35} mb="sm" />
                <Skeleton height={35} mb="sm" />
                <Skeleton height={35} mb="sm" />
                <Skeleton height={35} mb="sm" />
                <Skeleton height={35} mb="sm" />
                <Skeleton height={35} mb="sm" />
              </Box>
            )}
          </Navbar.Section>
          <Navbar.Section>
            <UserMenu setTheme={setTheme} />
          </Navbar.Section>
        </Navbar>
      }>
      <>
        {!isLoading ? (
          <>
            {view === docStatusMap.dashboard ? (
              <Dashboard />
            ) : (
              <TableView
                data={data}
                view={view}
                fetchData={fetchData}
                transactionViews={transactionViews}
              />
            )}
          </>
        ) : (
          <Center maw={400} h={100} mx="auto">
            <Loader color="cyan" />
          </Center>
        )}
      </>
    </AppShell>
  );
}

export default Layout;
