import { useState, useCallback } from 'react';
import {
  AppShell,
  Center,
  useMantineTheme,
  Checkbox,
  Button,
  TextInput,
  Card,
  Group,
  Grid,
  Image,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import Particles from 'react-tsparticles';
import { loadSlim } from 'tsparticles-slim';
import LoginAdapter from '../adapters/loginAdapter';
import Logo from '../assets/images/logo/CMYK/logo/png/Juice_Primary_Logo.png';

export default function Login() {
  const [loading, setLoading] = useState(false);
  const theme = useMantineTheme();

  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    //await loadFull(engine);
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  const logIn = async () => {
    window.sessionStorage.clear();
    setLoading(true);
    const data = form.values;
    const userDetails = await LoginAdapter.doLogin(data);

    if (userDetails !== null) {
      window.sessionStorage.setItem('userId', userDetails.user.userId);
      window.sessionStorage.setItem('userEmail', userDetails.user.userEmail);
      window.sessionStorage.setItem('userName', userDetails.user.userName);
      window.sessionStorage.setItem('companyId', userDetails.user.companyId);
      localStorage.setItem('accessToken', userDetails.accessToken);
      window.location.href = '/';
      setLoading(false);
    } else {
      setLoading(false);
      form.setErrors({
        username: 'Invalid email',
        password: 'Invalid password',
      });
    }
  };

  const form = useForm({
    initialValues: {
      username: '',
      password: '',
      termsOfService: false,
    },

    validate: {
      username: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm">
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          background: {
            color: {
              value: '#012732',
            },
          },
          fpsLimit: 120,
          interactivity: {
            events: {
              onClick: {
                enable: false,
                mode: 'push',
              },
              onHover: {
                enable: true,
                mode: 'repulse',
              },
              resize: true,
            },
            modes: {
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: '#039DC8',
            },
            links: {
              color: '#039DC8',
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            move: {
              direction: 'none',
              enable: true,
              outModes: {
                default: 'bounce',
              },
              random: false,
              speed: 0.8,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 80,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: 'circle',
            },
            size: {
              value: { min: 1, max: 5 },
            },
          },
          detectRetina: true,
        }}
      />
      <Center maw={3000} h={600} mx="auto">
        <Card shadow="xl" padding="sm" radius="xl" withBorder>
          <Grid>
            <Grid.Col span={6}>
              <Center maw={400} h={250} mx="auto">
                <Image width={250} height={350} src={Logo} fit="contain" />
              </Center>
            </Grid.Col>
            <Grid.Col span={6}>
              <Card padding="lg" radius="md">
                <form onSubmit={form.onSubmit(() => logIn())}>
                  <TextInput
                    withAsterisk
                    label="Email"
                    placeholder="your@email.com"
                    {...form.getInputProps('username')}
                  />
                  <TextInput
                    withAsterisk
                    label="Password"
                    type="password"
                    {...form.getInputProps('password')}
                  />
                  <Checkbox
                    mt="md"
                    label="Remember me"
                    {...form.getInputProps('termsOfService', {
                      type: 'checkbox',
                    })}
                  />
                  <Group position="right" mt="md">
                    {loading ? (
                      <Button type="submit" loading>
                        LOG IN
                      </Button>
                    ) : (
                      <Button type="submit">LOG IN</Button>
                    )}
                  </Group>
                </form>
              </Card>
            </Grid.Col>
          </Grid>
        </Card>
      </Center>
    </AppShell>
  );
}
