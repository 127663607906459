import {
  SimpleGrid,
  Text,
  Card,
  Title,
  Group,
  Badge,
  MediaQuery,
} from '@mantine/core';
import formatCurrency from '../utils/formatCurrency';

const PurchaseOrderCard = ({
  poNumber,
  DocStatus = '',
  DocType = '',
  SupplierCode = '',
  SupplierName = '',
  DocTotal = '',
  VatSum = '',
}) => {
  const data = [
    {
      key: 'Doc Type',
      value: DocType === 'S' ? 'SERVICE INVOICE' : 'STOCKED INVOICE',
    },
    { key: 'Supplier Code', value: SupplierCode },
    { key: 'Supplier Name', value: SupplierName },
    { key: 'Amount (A)', value: formatCurrency(DocTotal - VatSum) },
    { key: 'VAT (B)', value: formatCurrency(VatSum) },
    { key: 'Total (A + B)', value: formatCurrency(DocTotal) },
  ];

  return (
    <MediaQuery largerThan="md" styles={{ minWidth: 840, maxWidth: 840 }}>
      <Card
        shadow="none"
        padding="lg"
        withBorder
        style={{
          width: '100%',
          margin: '0 auto',
        }}>
        {/* Head section */}
        <CardHeader poNumber={poNumber} docStatus={DocStatus} />

        {/* Body section */}
        <KeyValueGrid data={data}></KeyValueGrid>
      </Card>
    </MediaQuery>
  );
};

const CardHeader = ({ poNumber, docStatus }) => {
  return (
    <Group position="apart">
      <Title order={4} mb="md">
        PO # {poNumber}
      </Title>
      {docStatus === 'O' && (
        <Badge variant="filled" color="green">
          OPEN
        </Badge>
      )}
      {docStatus === 'C' && (
        <Badge variant="filled" color="red">
          CLOSED
        </Badge>
      )}
    </Group>
  );
};

const KeyValueGrid = ({ data = [] }) => {
  return (
    <SimpleGrid
      cols={3}
      breakpoints={[
        { maxWidth: 'sm', cols: 2 },
        { minWidth: 'sm', maxWidth: 'md', cols: 2 },
        { minWidth: 'md', cols: 3 },
      ]}
      spacing="lg">
      {data.map(({ key, value }, index) => (
        <KeyValueItem key={index} title={key} value={value}></KeyValueItem>
      ))}
    </SimpleGrid>
  );
};

const KeyValueItem = ({ title, value }) => {
  return (
    <div style={{ paddingRight: 25 }}>
      {/* Key */}
      <Text weight={700} size="sm" color="#555E68">
        {title}
      </Text>
      {/* Value */}
      <Text size="sm" color="#555E68">
        {value}
      </Text>
    </div>
  );
};

export default PurchaseOrderCard;
