import { useEffect, useState, useCallback } from 'react';
import {
  Modal,
  Group,
  Card,
  Grid,
  Title,
  Divider,
  Space,
  SimpleGrid,
  Select,
  Text,
  Tooltip,
  Alert,
  Loader,
  Tabs,
  Stack,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { useDisclosure } from '@mantine/hooks';
import { Button, TextInput } from '@mantine/core';
import TransactionAdapter from '../adapters/transactionAdapter.js';
import { useForm } from '@mantine/form';

import 'react-data-grid/lib/styles.css';

import PDFViewer from './PDFViewer.jsx';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import { ViewButtonComponent } from './buttonComponent.jsx';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '@inovua/reactdatagrid-community/index.css';
import {
  IconArrowLeft,
  IconCircleCheck,
  IconInfoCircle,
  IconMailFast,
} from '@tabler/icons-react';

import docStatusMap from '../constants/docStatusMap.js';
import DocHistoryDropdown from './DocHistoryDropdown.jsx';
import InvoiceApproverAdapter from '../adapters/invoiceApproversAdapter.js';
import moment from 'moment';
import SelectApproverItem from './SelectApproverItem.jsx';
import PurchaseOrderLines from './PurchaseOrderLines.jsx';
import usePurchaseOrderDetails from '../hooks/usePurchaseOrderDetails.jsx';
import docStatusNameMap from '../constants/docStatusNameMap.js';
import useSignedUrl from '../hooks/useSignedUrl.jsx';
import formatCurrency from '../utils/formatCurrency';
// import PurchaseOrderCard from './PurchaseOrderCard.jsx';

const SEPARATOR = ',';

const hideDocHistoryFrom = [
  docStatusMap.zeroQ,
  docStatusMap.completeQ,
  docStatusMap.squashQ,
];

// List of views where Squash button is expected to be visible.
const squashViews = [
  docStatusMap.manualQ,
  docStatusMap.queryQ,
  docStatusMap.poNoMatchQ,
  docStatusMap.noGrvQ,
  docStatusMap.serviceInvoiceQ,
  // TODO: The following ids seem to be orphan ones. Remove them later.
  'dbd7b901-4d16-47bb-9095-464a5a0055d0',
  '82a717b9-4606-498f-aa36-219531bc2acd',
  '207b8b3e-e3de-4499-909b-5ce69d3da069',
  'e3eeb590-4a2a-41d8-96a2-8e9de6f2fcdd',
];

// List of views where Push Back button is expected to be visible.
const pushBackViews = [docStatusMap.batchQ];

// List of views where detailed invoice view will allow
// the users to update the form values...
const editableViews = [
  docStatusMap.manualQ,
  docStatusMap.poNoMatchQ,
  docStatusMap.queryQ,
  docStatusMap.serviceInvoiceQ,
  docStatusMap.noGrvQ,
];

const filterValue = [
  { name: 'invoiceNumber', operator: 'inlist', type: 'select', value: '' },
  { name: 'poNumber', operator: 'inlist', type: 'select', value: '' },
  { name: 'supplier', operator: 'inlist', type: 'select', value: '' },
  { name: 'dateAdded', operator: 'eq', type: 'date', value: '' },
  { name: 'invoiceDate', operator: 'eq', type: 'date', value: '' },
];

const expenseColumn = [
  {
    name: 'Code',
    header: 'Code',
    defaultFlex: 2,
    defaultVisible: false,
    sortable: false,
  },
  {
    name: 'Description',
    header: 'Description',
    defaultFlex: 4,
    sortable: false,
  },
  {
    name: 'Quantity',
    header: 'Quantity',
    render: ({ value }) => Number(value).toFixed(2),
    defaultFlex: 1,
    headerAlign: 'center',
    textAlign: 'end',
    sortable: false,
  },
  {
    name: 'UnitPrice',
    header: 'Unit Price',
    render: ({ value }) => formatCurrency(value),
    defaultFlex: 1,
    textAlign: 'end',
    headerAlign: 'center',
    sortable: false,
  },
  {
    name: 'Discount',
    header: 'Discount',
    defaultFlex: 1,
    textAlign: 'center',
    sortable: false,
  },
  {
    name: 'LineTotal',
    header: 'Line Total',
    render: ({ value }) => formatCurrency(value),
    defaultFlex: 1,
    textAlign: 'end',
    headerAlign: 'center',
    sortable: false,
  },
];

const downloadBlob = (blob, fileName = 'grid-data.csv') => {
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.position = 'absolute';
  link.style.visibility = 'hidden';

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

function DataGridView2({ data, view, fetchData, transactionViews }) {
  const [gridRef, setGridRef] = useState(null);
  const [modalOpened, { open, close }] = useDisclosure(false);
  const [isTableSelectable, setIsTableSelectable] = useState(false);
  const [queueRows, setQueueRows] = useState([]);
  const [currentItem, setCurrentItem] = useState({});
  const [originalItem, setOriginalItem] = useState({});
  const [filteredData, setFilteredData] = useState(data);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [templateObject, setTemplateObject] = useState({});
  const [botObject, setBotObject] = useState({});
  const [expenseRows, setExpenseRows] = useState({});
  const [botExpenseRows, setBotExpenseRows] = useState({});
  const [lastClickedRow, setLastClickedRow] = useState(0);
  const [isViewSwitched, setIsViewSwitched] = useState(false);
  const [approvers, setApprovers] = useState([]);
  const [approverUpdationInProgress, setApproverUpdationInProgress] =
    useState(false);
  const [viewNewTableHeaders, setNewViewTableHeaders] = useState([]);
  const [originalPoNumber, setOriginalPoNumber] = useState(null);
  const [purchaseOrder, purchaseOrderError, purchaseOrderLoading] =
    usePurchaseOrderDetails(originalPoNumber);
  const [poLines, setPoLines] = useState([]);
  const [documentKey, setDocumentKey] = useState(null);
  const [signedURL, docError, docIsLoading] = useSignedUrl(documentKey);

  window.moment = moment;

  const switchView = (fileURL) => {
    setIsViewSwitched(!isViewSwitched);
    if (fileURL !== null) {
      window.open(fileURL, '_blank');
    }
  };

  const refresh = () => {
    fetchData();
  };

  const onRowClick = useCallback((rowProps) => {
    setLastClickedRow(rowProps.rowIndex);
  }, []);

  useEffect(() => {
    getApproversList();
  }, []);

  useEffect(() => {
    const poLines = (purchaseOrder?.PurchaseOrders?.Lines || []).map(
      (poLine, index) => {
        return {
          ...poLine,
          id: index,
        };
      },
    );

    setPoLines(poLines);
  }, [purchaseOrder]);

  const getApproversList = async () => {
    const approvers = await InvoiceApproverAdapter.getInvoiceAprovers();
    setApprovers(
      approvers.map((approver) => {
        return {
          value: approver.email,
          name: approver.name,
          email: approver.email,
          limit: approver.approvalLimit,
          department: approver.department,
          label: `${approver.name} (${approver.email})`,
        };
      }),
    );
  };

  const updateApproverDetails = useCallback(
    (approverEmail) => {
      const newApprover = approvers.find(
        (approver) => approver.value === approverEmail,
      );

      modals.openConfirmModal({
        title: 'Confirmation',
        children: (
          <Text size="sm">
            {`This action will set "${newApprover.name}" as an approver for this invoice. We will deliver the approval email to "${approverEmail}" upon your confirmation.`}
          </Text>
        ),
        labels: { confirm: 'Confirm', cancel: 'Cancel' },
        onCancel: () => {
          return false;
        },
        onConfirm: async () => {
          setApproverUpdationInProgress(true);

          const invoiceId = currentItem._id;
          const approverEmail = newApprover.value;
          const approverName = newApprover.name;
          const supplierName = currentItem.Document?.supplier?.name || '';
          const poNumber = currentItem.Document?.po_number || '';
          const invoiceAmount = currentItem.Document?.totals?.total || '';
          const invoiceNumber = currentItem.Document?.invoice_number || '';
          const invoiceFileName = currentItem.file_name || '';

          const payload = {
            invoiceId,
            approverEmail,
            approverName,
            supplierName,
            poNumber,
            invoiceAmount,
            invoiceNumber,
            invoiceFileName,
          };

          try {
            const updatedDoc =
              await InvoiceApproverAdapter.assignInvoiceApprover(payload);
            setCurrentItem({
              ...currentItem,
              approver: { ...updatedDoc.approver },
            });
          } catch (error) {
            console.log(
              '🚀 ~ file: DataGrid2.jsx:181 ~ onConfirm: ~ error:',
              error,
            );
            // TODO: Notify user about the action failure...
          } finally {
            setApproverUpdationInProgress(false);
            console.log(
              '🚀 ~ file: DataGrid2.jsx ~ handleApproverChange: currentItem (Updated)',
              currentItem,
            );
          }
        },
      });
    },
    [approvers, currentItem],
  );

  const onExpensesEditRemoveRow = useCallback(() => {
    if (lastClickedRow !== null) {
      let data = [...expenseRows];
      data.splice(lastClickedRow, 1);
      setExpenseRows(data);
      handleInputChange('expenseRows', data);
    }
  }, [expenseRows, lastClickedRow]);

  const onExpensesEditAddRow = useCallback(() => {
    let data = [...expenseRows];
    let location = data.length;
    let newRow = {
      LineNum: '',
      Code: '',
      Description: '',
      Quantity: '',
      UnitPrice: '',
      LineTotal: '',
      LineStatus: '',
      LineFormatCode: '',
    };
    newRow.id = location;
    data[location] = newRow;
    const lastItem = data.pop();
    data.splice(lastClickedRow, 0, lastItem);
    for (let index = 0; index < data.length; index++) {
      data[index].id = index;
    }
    setExpenseRows(data);
    handleInputChange('expenseRowsNew', data);
  }, [expenseRows, lastClickedRow]);

  const getViewDetails = () => {
    let rows = [];
    for (let index = 0; index < filteredData.length; index++) {
      const element = filteredData[index];
      const docType = (element?.Document?.document_type || '').toLowerCase();

      // As of now we only want to show documents of type "Invoice".
      // In future, we may support more document types like a Purchase Order document.
      if (docType === 'invoice') {
        rows.push({
          id: element._id,
          dateAdded:
            element.date_added.substring(0, 10) +
            ' ' +
            (Number(element.date_added.substring(11, 13)) + 2) +
            element.date_added.substring(13, 16),
          invoiceNumber: element?.Document?.invoice_number,
          accountNumber: element?.Document?.account_number,
          message: element?.message,
          processStatus: element.state,
          supplier: element?.Document?.supplier?.name,
          poNumber: element?.Document?.po_number,
          invoiceTotal: Number(element?.Document?.totals?.total || 0).toFixed(
            2,
          ),
          invoiceDate: element.Document?.invoice_date,
        });
      }
    }
    setQueueRows(rows);

    let newGridHeaderList = [];
    if (transactionViews.length !== 0) {
      const currentViewDetails = transactionViews.find(
        (item) => item.statusId === view,
      );
      setIsTableSelectable(currentViewDetails.selectableRows);

      if (currentViewDetails.viewable) {
        newGridHeaderList.push({
          name: 'actions',
          header: 'Actions',
          textAlign: 'center',
          minWidth: 100,
          render: ({ data }) => (
            <Button onClick={() => viewItem(data.id)}>VIEW</Button>
          ),
        });
      }

      for (
        let index = 0;
        index < currentViewDetails.tableHeaders.length;
        index++
      ) {
        const element = currentViewDetails.tableHeaders[index];
        let tableheaderObject = {
          name: element.selector,
          header: element.name,
          defaultFlex: 1,
          minWidth: 160,
        };
        if (element.type === 'date') {
          tableheaderObject = {
            name: element.selector,
            header: element.name,
            defaultFlex: 1,
            minWidth: 160,
            filterEditor: DateFilter,
            filterEditorProps: () => {
              return {
                dateFormat: 'YYYY-MM-DD',
                cancelButton: false,
                highlightWeekends: false,
              };
            },
          };
        }
        if (element.type !== 'date') {
          const values = [];
          for (const obj of rows) {
            if (obj.hasOwnProperty(element.selector)) {
              let value = obj[element.selector];
              if (typeof value !== 'string') {
                try {
                  value = value.toString();
                  if (!values.includes(value)) {
                    values.push(value);
                  }
                } catch (error) {}
              } else {
                if (!values.includes(value)) {
                  values.push(value);
                }
              }
            }
          }
          tableheaderObject = {
            name: element.selector,
            header: element.name,
            defaultFlex: 1,
            minWidth: 160,
            filterEditor: SelectFilter,
            filterEditorProps: {
              multiple: true,
              wrapMultiple: false,
              dataSource: values.map((c) => {
                return { id: c, label: c };
              }),
            },
          };
        }
        newGridHeaderList.push(tableheaderObject);
      }

      setNewViewTableHeaders(newGridHeaderList);
    }
  };

  useEffect(() => {
    getViewDetails();
  }, []);

  useEffect(() => {
    if (lastClickedRow !== null) {
      // onExpensesEditAddRow(lastClickedRow);
    }
  }, [lastClickedRow, onExpensesEditAddRow]);

  const gridStyle = { minHeight: 300 };

  const onExpensesEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      const data = [...expenseRows];
      // let dataList = [...expenseRowList];
      if (rowId === expenseRows.length) {
        rowId = rowId - 1;
      }
      console.log(data[rowId][columnId]);
      data[rowId][columnId] = value;

      // Check if the edited column is 'Quantity' or 'UnitPrice'
      // then atuomatically update the 'LineTotal' column.
      // if (columnId === 'Quantity' || columnId === 'UnitPrice') {
      //   data[rowId]['LineTotal'] = data[rowId]['Quantity'] * data[rowId]['UnitPrice'];
      // }

      setExpenseRows(data);
      handleInputChange('expenseRows', data);
    },
    [expenseRows],
  );

  const handleInputChange = (key, value) => {
    setTemplateObject({ ...templateObject, [key]: value });
  };

  const handleBotInputChange = (key, value) => {
    setBotObject({ ...botObject, [key]: value });
  };

  const submitOpenItem = async (item) => {
    toast.info('Busy processing case');
    let tempObject = templateObject;
    tempObject.botObject = botObject;
    tempObject.botObject.Expenses = botExpenseRows;
    let expenses = [];
    Object.keys(botObject).forEach((key) => {
      if (typeof botObject[key] === 'string' && botObject[key] === '') {
        toast.error(`Please enter ${key}`);
        return false;
      }
    });
    for (let index = 0; index < expenseRows.length; index++) {
      let element = expenseRows[index];
      delete element.id;
      const arrayFromObject = Object.values(element);
      for (let index = 0; index < arrayFromObject.length; index++) {
        const arrayElemnt = arrayFromObject[index];
        if (arrayElemnt !== '') {
          expenses.push(arrayFromObject);
          break;
        }
      }
    }

    tempObject.expenseRows = expenses;
    tempObject.botObject.lineItemData = expenseRows;
    close();

    const updatedDoc = getUpdatedDocObject(item, tempObject);
    updatedDoc.state = docStatusMap.zeroQ;

    let body = {
      transactionId: item._id,
      templateObject: updatedDoc,
    };

    await TransactionAdapter.updateTransaction(body);

    fetchData();
    toast.success('Case successfully processed');
  };

  const pushBackItem = async (item) => {
    toast.info('Pushing back to the Manual Queue');
    close();

    let body = {
      transactionId: item._id,
      templateObject: {
        state: docStatusMap.manualQ,
      },
    };

    await TransactionAdapter.updateTransaction(body);
    fetchData();
    toast.success('Pushed back succesfully');
  };

  const sqaushOpenItem = async (item) => {
    toast.info('Busy squashing case');
    close();

    let body = {
      transactionId: item._id,
      templateObject: {
        state: docStatusMap.squashQ,
      },
    };

    await TransactionAdapter.updateTransaction(body);

    fetchData();
    toast.success('Case squashed succesfully');
  };

  const pushToBatchQ = async (invoiceId) => {
    toast.info('Pushing to the Batch Queue.');

    close();

    const updatedDoc = {};
    updatedDoc.state = docStatusMap.batchQ;

    let body = {
      transactionId: invoiceId,
      templateObject: updatedDoc,
    };

    await TransactionAdapter.updateTransaction(body);

    fetchData();
  };

  const pushCurrentInvoiceToSapQ = async (invoiceId) => {
    if (!invoiceId) {
      return;
    }
    close();
    pushToSapQ([invoiceId]);
  };

  const pushToSapQ = async (invoiceIds) => {
    if (!Array.isArray(invoiceIds) || invoiceIds.length === 0) {
      return;
    }

    toast.info('Started pushing to SAP Queue', {
      type: toast.TYPE.INFO,
      autoClose: 3000,
    });

    try {
      await TransactionAdapter.pushToSapQ(invoiceIds);

      toast.info('Successfully pushed to SAP Queue', {
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
    } catch (e) {
      console.error('Error in pushing to SAP Queue: ', e);

      toast.info('Something went wrong while pushing to SAP Queue', {
        type: toast.TYPE.ERROR,
        autoClose: 3000,
      });
    } finally {
      fetchData();
    }
  };

  const failCasses = async () => {
    toast.info('Busy pushing cases back');
    for (let index = 0; index < selectedInvoices.length; index++) {
      const element = selectedInvoices[index];
      const matchedRecord = filteredData.find(
        (item) => item.transactionId === element.id,
      );
      const requestBody = {
        transactionId: matchedRecord.transactionId,
        statusId: matchedRecord.statusId,
        userId: sessionStorage.getItem('userId'),
      };
      await TransactionAdapter.failTransactions(requestBody);
    }
    fetchData();
    toast.info('Busy processing cases');
  };

  let itemForm = useForm({
    initialValues: {},
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  /**
   * Transforms the object structure to the current one.
   * This transformation aims to retrofit the implementation and
   * should be removed/improved later.
   */
  function getBotObject(doc) {
    const invoiceReceivedDate = new Date(doc.date_added)
      .toISOString()
      .split('T')[0];
    const {
      document_type,
      account_number,
      invoice_number,
      po_number,
      invoice_date,
    } = doc.Document;
    const { name: supplierName, vat_number } = doc.Document.supplier;
    const { total } = doc.Document.totals;
    const lineItems = (doc.Document.line_items || []).map((lineItem, index) => {
      return {
        LineNum: index,
        Code: lineItem.code,
        Description: lineItem.description,
        Quantity: lineItem.quantity,
        UnitPrice: lineItem.unit_rate,
        LineTotal: lineItem.total,
        Discount: lineItem.discount || 0,
        LineStatus: '',
        LineFormatCode: '',
      };
    });

    return {
      Document_Type: document_type || '',
      Account_Number: account_number || '',
      Invoice_Number: invoice_number || '',
      PO_Number: po_number || '',
      Supplier: supplierName || '',
      Invoice_Date: invoice_date || '',
      Invoice_Received_Date: invoiceReceivedDate || '',
      Total_Amount: (total || total === 0 ? total : '').toString(),
      Vat_Number: vat_number || '',
      lineItemData: lineItems,
      Expenses: [],
    };
  }

  /**
   * Transforms the object structure back to the new "document" structure.
   * This transformation aims to retrofit the implementation and
   * should be removed/improved later.
   */
  function getUpdatedDocObject(doc, bot) {
    const updatedDoc = { ...doc };

    updatedDoc.Document.document_type = bot.botObject.Document_Type;
    updatedDoc.Document.account_number = bot.botObject.Account_Number;
    updatedDoc.Document.invoice_number = bot.botObject.Invoice_Number;
    updatedDoc.Document.po_number = bot.botObject.PO_Number;
    updatedDoc.Document.supplier.name = bot.botObject.Supplier;
    updatedDoc.Document.supplier.vat_number = bot.botObject.Vat_Number;
    updatedDoc.Document.invoice_date = bot.botObject.Invoice_Date;
    updatedDoc.date_added = bot.botObject.Invoice_Received_Date;
    updatedDoc.Document.totals.total = bot.botObject.Total_Amount;
    updatedDoc.Document.line_items = (bot.botObject.lineItemData || []).map(
      (lineItem) => {
        return {
          code: lineItem.Code,
          description: lineItem.Description,
          quantity: lineItem.Quantity,
          unit_rate: lineItem.UnitPrice,
          total: lineItem.LineTotal,
        };
      },
    );

    return updatedDoc;
  }

  /**
   * Sets the `currentItem` state to the selected document version.
   * If user clears the document version history dropdown then
   * binds the `currentItem` to the `originalItem` state which acts as a backup.
   *
   * @param selectedDocVersion - selected document object. It will be "" when user
   * clears the Document Version History dropdown to go back to the current version.
   *
   * TODO: Possible Perf Improvement
   * The current implementation for doc version history can be further improved
   * by initially loading the limited column's data and when user selects the version
   * we can pull the full record for that specific version only.
   */
  const handleDocHistorySelection = (selectedDocVersion) => {
    const selectedDoc = selectedDocVersion || originalItem;
    let rows = [];

    setCurrentItem(selectedDoc);
    const botObject = getBotObject(selectedDoc);
    setBotObject(botObject);

    const templateObj = {
      matchedTemplateId: '',
      extraFields: {},
      botObject: { ...botObject },
      expenseRows: [],
      expenseRowsNew: [],
    };
    itemForm.setValues(templateObj);

    for (
      let index = 0;
      index < templateObj.botObject.lineItemData.length;
      index++
    ) {
      const element = templateObj.botObject.lineItemData[index];
      let rowItem = element;
      rowItem.id = index;
      rows.push(rowItem);
    }

    setExpenseRows(rows);
    setTemplateObject(templateObj);
  };

  const viewItem = async (index) => {
    toast.info('Loading document');

    let rows = [];

    const founditem = filteredData.find((item) => item._id === index);
    setCurrentItem(founditem);

    // Make a backup copy of the currnetly viewing document.
    // This helps in restoring the origial document on the viewer
    // when user clears the document version history dropdown.
    setOriginalItem(founditem);

    setOriginalPoNumber(founditem?.Document?.po_number);
    setDocumentKey(founditem?.file_name);

    // setBotObject(founditem.templateObject.botObject); // Original
    const botObject = getBotObject(founditem); // New
    setBotObject(botObject);

    // itemForm.setValues(founditem.templateObject); // Original
    const templateObj = {
      matchedTemplateId: '',
      extraFields: {},
      botObject: { ...botObject },
      expenseRows: [],
      expenseRowsNew: [],
    };
    itemForm.setValues(templateObj); // New

    // TODO: Uncomment this later.
    // let botRowExpenses = founditem.templateObject.botObject.Expenses;
    // if (botRowExpenses !== undefined) {
    //   for (let index = 0; index < botRowExpenses.length; index++) {
    //     const element = botRowExpenses[index];
    //     element.id = index;
    //     botRowExpenses[index] = element;
    //   }
    //   setBotExpenseRows(botRowExpenses);
    // }

    for (
      let index = 0;
      index < templateObj.botObject.lineItemData.length;
      index++
    ) {
      const element = templateObj.botObject.lineItemData[index];
      let rowItem = element;
      rowItem.id = index;
      rows.push(rowItem);
    }

    setExpenseRows(rows);
    setTemplateObject(templateObj);

    open();
  };

  const handleSelectionChange = useCallback((data) => {
    const ids = [];
    if (data.selected === true) {
      for (const obj of data.originalData) {
        ids.push(obj.id);
      }
    } else {
      for (const key in data.selected) {
        if (data.selected.hasOwnProperty(key)) {
          ids.push(data.selected[key].id);
        }
      }
    }
    setSelectedInvoices(ids);
  }, []);

  const exportCSV = () => {
    const columnsToSkip = ['actions'];
    const columns = gridRef.current.visibleColumns;

    const header = columns
      .filter((c) => !columnsToSkip.includes(c.name))
      .map((c) => c.header || c.name)
      .join(SEPARATOR);

    const rows = gridRef.current.dataSource.map((data) =>
      columns
        .filter((c) => !columnsToSkip.includes(c.name))
        .map((c) => data[c.id])
        .join(SEPARATOR),
    );

    const contents = [header].concat(rows).join('\n');
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

    downloadBlob(blob);
  };

  const appendPurchaseOrderTotal = (tabTitle = '') => {
    const total = formatCurrency(purchaseOrder?.PurchaseOrders?.DocTotal);
    return total ? `${tabTitle} - ${total}` : tabTitle;
  };

  return (
    <Card>
      <Grid>
        <Grid.Col span="content">
          <Group justify="space-between">
            <ViewButtonComponent
              view={view}
              processCasses={() => pushToSapQ(selectedInvoices)}
              failCasses={failCasses}
            />
            <Button
              variant="gradient"
              gradient={{ from: 'yellow', to: 'yellow', deg: 60 }}
              onClick={refresh}>
              REFRESH
            </Button>
          </Group>
        </Grid.Col>
        <Grid.Col span="auto"></Grid.Col>
        <Grid.Col span="content">
          <Button
            variant="gradient"
            gradient={{ from: 'blue', to: 'blue', deg: 60 }}
            onClick={exportCSV}>
            EXPORT TO CSV
          </Button>
        </Grid.Col>
      </Grid>
      <Space h="md" />
      <ReactDataGrid
        handle={setGridRef}
        idProperty="id"
        style={{ minHeight: 'calc(100dvh - 116px)' }}
        editable={false}
        columns={viewNewTableHeaders}
        dataSource={queueRows}
        checkboxColumn={isTableSelectable}
        onSelectionChange={handleSelectionChange}
        defaultFilterValue={filterValue}
        pagination
        defaultLimit={15}
      />

      <Modal opened={modalOpened} onClose={close} fullScreen>
        <SimpleGrid cols={isViewSwitched === true ? 1 : 2}>
          <Card shadow="sm" radius="md">
            {/* <Card withBorder> */}

            <Group justify="space-between">
              <Button
                type="button"
                leftIcon={<IconArrowLeft size={14}></IconArrowLeft>}
                onClick={() => close()}>
                GO BACK
              </Button>
              {isViewSwitched === true ? (
                <Button
                  type="button"
                  variant="gradient"
                  gradient={{ from: 'yellow', to: 'yellow', deg: 60 }}
                  onClick={() => switchView(null)}>
                  SWITCH VIEW
                </Button>
              ) : null}
            </Group>

            {/* <SimpleGrid cols={2}> */}
            {/* <Box> */}
            <Group position="apart">
              <Stack spacing="none">
                <Title order={3}>Invoice Details </Title>
                <Text color="gray">{docStatusNameMap[view]}</Text>
              </Stack>
              {/* <span></span> */}
              {hideDocHistoryFrom.includes(view) ? (
                <></>
              ) : (
                <DocHistoryDropdown
                  sourceId={currentItem._id}
                  onSelect={handleDocHistorySelection}></DocHistoryDropdown>
              )}
            </Group>
            <Divider my="sm" />
            {/* </Box> */}
            {/* </SimpleGrid> */}
            {/* </Card> */}

            {/* Error Message Alert */}
            {currentItem.message && (
              <Alert
                title="Error"
                variant="light"
                color="red"
                icon={<IconInfoCircle />}>
                {currentItem.message}
              </Alert>
            )}

            {/* Service Invoice Approver Alert - No Approver Selected */}
            {view === docStatusMap.serviceInvoiceQ &&
              !currentItem.approver &&
              !approverUpdationInProgress && (
                <Alert
                  title="Approval Required"
                  variant="light"
                  color="blue"
                  my="md"
                  icon={<IconInfoCircle />}>
                  This service invoice needs to be reviewed by an authorized
                  approver before it can proceed. Please select an approver from
                  the list to ensure timely processing.
                </Alert>
              )}

            {/* Service Invoice Approver Alert - Approver has approved. */}
            {[
              docStatusMap.batchQ,
              docStatusMap.sapWaitingQ,
              docStatusMap.completeQ,
            ].includes(view) &&
              currentItem.approver &&
              !approverUpdationInProgress &&
              currentItem?.approver?.has_approved && (
                <Alert
                  title="Approved Invoice"
                  variant="light"
                  color="green"
                  my="md"
                  icon={<IconInfoCircle />}>
                  <Text>
                    {`This invoice has been approved by ${currentItem.approver.name} (${currentItem.approver.email}).`}
                  </Text>
                </Alert>
              )}

            {/* Service Invoice Approver Alert - Has approver but not approved. */}
            {view === docStatusMap.serviceInvoiceQ &&
              currentItem.approver &&
              !currentItem.approver?.has_approved &&
              !approverUpdationInProgress && (
                <Alert
                  title="Awaiting Approval"
                  variant="light"
                  color="orange"
                  my="md">
                  <p>
                    <Text>
                      {`This invoice requires an approval from ${currentItem.approver.name} (${currentItem.approver.email}).`}
                    </Text>
                  </p>

                  <Group position="left">
                    <Tooltip
                      withArrow
                      label={`This will resend invoice approval email to "${currentItem.approver.email}".`}>
                      <Button
                        type="button"
                        color="orange"
                        variant="filled"
                        leftIcon={<IconMailFast />}
                        onClick={() =>
                          updateApproverDetails(currentItem?.approver?.email)
                        }>
                        RESEND APPROVAL
                      </Button>
                    </Tooltip>
                    <Tooltip
                      withArrow
                      label={
                        'This will mark this invoice as approved by you and move it to the next stage.'
                      }>
                      <Button
                        type="button"
                        color="orange"
                        variant="outline"
                        leftIcon={<IconCircleCheck />}
                        onClick={() => pushToBatchQ(currentItem._id)}>
                        SELF APPROVE
                      </Button>
                    </Tooltip>
                  </Group>
                </Alert>
              )}

            {/* Service Invoice Approver Alert - Approver updation request in progress. */}
            {view === docStatusMap.serviceInvoiceQ &&
              approverUpdationInProgress && (
                <Alert
                  title="Setting Approver"
                  variant="light"
                  color="blue"
                  my="md"
                  icon={<Loader color="blue" size="xs" />}>
                  Saving the invoice approver details.
                </Alert>
              )}

            <Card.Section inheritPadding py="md">
              <form
                onSubmit={itemForm.onSubmit((values) => console.log(values))}>
                <SimpleGrid cols={isViewSwitched === true ? 4 : 3}>
                  {/* Invoice Details Fields */}
                  {Object.keys(botObject).map(
                    (key) =>
                      typeof botObject[key] === 'string' &&
                      key !== 'approver' && (
                        <TextInput
                          key={key}
                          id={key + '_input'}
                          label={key.toUpperCase().replaceAll('_', ' ')}
                          disabled={editableViews.includes(view) ? false : true}
                          value={botObject[key] || ''}
                          error={
                            !botObject[key]
                              ? 'Please set ' +
                                key.toUpperCase().replaceAll('_', ' ') +
                                '.'
                              : ''
                          }
                          onChange={(e) =>
                            handleBotInputChange(key, e.target.value)
                          }
                          withAsterisk
                        />
                      ),
                  )}

                  {/* Invoice Approver Dropdown */}
                  {view === docStatusMap.serviceInvoiceQ && (
                    <Select
                      id="approver_select"
                      label="APPROVER"
                      placeholder="Select an approver"
                      data={approvers.filter((approver) => {
                        // Exclude approvers whose Approval Limit < Invoice Total.
                        return (
                          approver.limit >= Number(botObject['Total_Amount'])
                        );
                      })}
                      itemComponent={SelectApproverItem}
                      value={currentItem.approver?.email || ''}
                      onChange={updateApproverDetails}
                      searchable
                      nothingFound="No match found..."
                      withAsterisk
                    />
                  )}
                </SimpleGrid>
              </form>
            </Card.Section>

            {/* Invoice remarks. */}
            {currentItem.Document?.remarks && (
              <Alert
                title="Invoice Remarks"
                variant="outline"
                color="gray"
                mb="md">
                <Text size="sm">{currentItem.Document.remarks}</Text>
              </Alert>
            )}

            {/* TODO: This should be extracted to a separate component. */}
            <Tabs
              variant="default"
              defaultValue={
                currentItem.Document?.po_number ? 'poLines' : 'invoiceLines'
              }>
              <Tabs.List>
                <Tabs.Tab value="invoiceLines">Invoice Lines</Tabs.Tab>
                <Tabs.Tab value="poLines">
                  {appendPurchaseOrderTotal('Purchase Order')}
                </Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value="invoiceLines" pt="xs">
                {/* Add Line / Remove Line Buttons */}
                {editableViews.includes(view) && (
                  <Group position="right" justify="space-between" mb="xs">
                    <Button
                      onClick={() => onExpensesEditAddRow(0)}
                      id={`button-AddLine`}>
                      ADD LINE
                    </Button>
                    <Button
                      onClick={() => onExpensesEditRemoveRow(0)}
                      variant="gradient"
                      gradient={{ from: 'orange', to: 'orange', deg: 60 }}>
                      REMOVE LINE
                    </Button>
                  </Group>
                )}

                {/* Invoice Line Items Grid */}
                <ReactDataGrid
                  idProperty="id"
                  style={gridStyle}
                  onEditComplete={onExpensesEditComplete}
                  editable={editableViews.includes(view) ? true : false}
                  columns={expenseColumn}
                  dataSource={expenseRows}
                  onRowClick={onRowClick}
                />
              </Tabs.Panel>
              <Tabs.Panel value="poLines" pt="xs">
                {/* <Stack> */}
                {/* <PurchaseOrderCard
                    poNumber={currentItem?.Document?.po_number}
                    {...purchaseOrder?.PurchaseOrders}></PurchaseOrderCard> */}
                <PurchaseOrderLines
                  style={gridStyle}
                  poLines={poLines}
                  isLoading={purchaseOrderLoading}
                  error={purchaseOrderError}
                />
                {/* </Stack> */}
              </Tabs.Panel>
            </Tabs>

            <Divider my="sm" />

            <Grid>
              <Grid.Col span={6}>
                <Group position="left">
                  {pushBackViews.includes(view) && (
                    <Tooltip
                      withArrow
                      label="Moves this transaction back to Manual Queue.">
                      <Button
                        variant="gradient"
                        gradient={{ from: 'orange', to: 'orange', deg: 60 }}
                        type="button"
                        onClick={() => pushBackItem(currentItem)}>
                        PUSH BACK
                      </Button>
                    </Tooltip>
                  )}
                  {squashViews.includes(view) ? (
                    <Tooltip
                      withArrow
                      label="Permanently discards and moves this record to the Squashed Queue.">
                      <Button
                        variant="gradient"
                        gradient={{ from: 'red', to: 'orange', deg: 60 }}
                        type="button"
                        onClick={() => sqaushOpenItem(currentItem)}>
                        SQUASH
                      </Button>
                    </Tooltip>
                  ) : null}
                </Group>
              </Grid.Col>
              <Grid.Col span={6}>
                <Group position="right">
                  {/* Update & Cancel Buttons */}
                  {editableViews.includes(view) && (
                    <Tooltip
                      withArrow
                      label="Moves this record to Zero Queue to validate your changes.">
                      <Button
                        variant="gradient"
                        gradient={{ from: 'green', to: 'green', deg: 60 }}
                        type="submit"
                        onClick={() => submitOpenItem(currentItem)}>
                        UPDATE
                      </Button>
                    </Tooltip>
                  )}

                  {/* Process Transaction Button */}
                  {view === docStatusMap.batchQ && (
                    <Tooltip
                      withArrow
                      label="Moves this transaction to SAP Waiting Queue.">
                      <Button
                        variant="gradient"
                        gradient={{ from: 'green', to: 'green', deg: 60 }}
                        type="submit"
                        onClick={() =>
                          pushCurrentInvoiceToSapQ(currentItem._id)
                        }>
                        PROCESS TRANSACTION
                      </Button>
                    </Tooltip>
                  )}
                </Group>
              </Grid.Col>
            </Grid>
          </Card>
          <>
            {isViewSwitched === false ? (
              <PDFViewer
                fileURL={signedURL}
                loading={docIsLoading}
                error={docError}
                switchView={switchView}
              />
            ) : null}
          </>
        </SimpleGrid>
      </Modal>
    </Card>
  );
}

export default DataGridView2;
