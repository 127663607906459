import { Center, Loader, Text } from '@mantine/core';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import formatCurrency from '../utils/formatCurrency';

/**
 * Sample Purchase Order Line Item...
 *
 * {
 *  "LineNum": 0,
 *  "Description": "Pre employment Medical exam - Mmelesi expires 23/07/2024 (1 x 1060,87)",
 *  "Quantity": ".000000",
 *  "UnitPrice": "1060.870000",
 *  "LineTotal": "1060.870000",
 *  "LineStatus": "O",
 *  "LineFormatCode": "13430103017605"
 * }
 */
const columns = [
  {
    name: 'LineFormatCode',
    header: 'Code',
    defaultVisible: false,
    defaultFlex: 1,
    sortable: false,
  },
  {
    name: 'Description',
    header: 'Description',
    defaultFlex: 3,
    sortable: false,
  },
  {
    name: 'Quantity',
    header: 'Quantity',
    render: ({ value }) => Number(value).toFixed(2),
    defaultFlex: 1,
    headerAlign: 'center',
    textAlign: 'end',
    sortable: false,
  },
  {
    name: 'UnitPrice',
    header: 'Unit Price',
    render: ({ value }) => formatCurrency(value),
    defaultFlex: 1,
    textAlign: 'end',
    headerAlign: 'center',
    sortable: false,
  },
  {
    name: 'LineTotal',
    header: 'Line Total',
    render: ({ value }) => formatCurrency(value),
    defaultFlex: 1,
    textAlign: 'end',
    headerAlign: 'center',
    sortable: false,
  },
  {
    name: 'LineStatus',
    header: 'Line Status',
    defaultFlex: 1,
    textAlign: 'center',
    sortable: false,
  },
];

const PurchaseOrderLines = ({ poLines, isLoading, error, ...rest }) => {
  if (isLoading) {
    return (
      <div {...rest}>
        <Center maw={300} h={300} m="auto">
          <Loader color="cyan" />
        </Center>
      </div>
    );
  }

  if (error) {
    return (
      <div {...rest}>
        <Center maw={300} h={300} m="auto">
          <Text size={'sm'} color="red">
            Failed to fetch purchase order line items.
          </Text>
        </Center>
      </div>
    );
  }

  return (
    <div {...rest}>
      <ReactDataGrid
        idProperty="id"
        columns={columns}
        dataSource={poLines}></ReactDataGrid>
    </div>
  );
};

export default PurchaseOrderLines;
