import { useCallback } from 'react';
import { Title, Card, Divider, Group, Button, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import InvoiceApproversList from './InvoiceApproversList';
import InvoiceApproverForm from './InvoiceApproverForm';

function InvoiceApprovers() {
  const [modalOpened, { open: openModal, close: closeModal }] =
    useDisclosure(false);

  const handleFormSuccess = useCallback(() => {
    toast.success('Invoice Approver details saved successfully.');
    closeModal();
    // TODO: Reload -> <InvoiceApproversList />
  }, [closeModal]);

  const handleFormError = useCallback(() => {
    toast.error('Something went wrong. Please try again.');
  }, []);

  return (
    <>
      {/* Invoice Approvers Section */}
      <Card>
        <Group position="apart">
          <Title order={3}>Invoice Approvers</Title>

          {/* Add or Update Invoice Approver */}
          <Button
            type="button"
            onClick={openModal}
            leftIcon={<IconPlus size={14}></IconPlus>}>
            ADD INVOICE APPROVER
          </Button>
        </Group>
        <Divider my="sm" />

        {/* Invoice Approver's List */}
        <InvoiceApproversList />
      </Card>

      {/* Add / Edit Invoice Approver Modal */}
      <Modal
        size="md"
        opened={modalOpened}
        onClose={closeModal}
        title="New Invoice Approver"
        closeOnClickOutside={false}
        centered>
        <InvoiceApproverForm
          onSuccess={handleFormSuccess}
          onError={handleFormError}></InvoiceApproverForm>
      </Modal>
    </>
  );
}

export default InvoiceApprovers;
