import httpClient from '../utils/httpClient';
import GLOBAL from '../config/global';

/**
 * Fetches the list of invoice approvers from the server.
 * @returns {Promise<Object>} Array of objects of invoice approvers - [{ "_id", "email", "name", "department" },...]
 */
const getInvoiceAprovers = async () => {
  try {
    const response = await httpClient.get(
      `${GLOBAL.API_URL}/getInvoiceApprovers`,
    );
    return response.data.message;
  } catch (error) {
    console.error('Error fetching invoice approvers:', error);
    throw new Error('Failed to fetch invoice approvers.');
  }
};

/**
 * Sets or upserts an invoice approver on the server.
 * @param {Object} approver - The invoice approver object to upsert.
 * @param {string} approver.email - The email of the approver.
 * @param {string} approver.name - The name of the approver.
 * @param {string} approver.department - The department of the approver.
 * @returns {Promise<Object>} The response from the server.
 */
const setInvoiceApprover = async (approver) => {
  try {
    const response = await httpClient.post(
      `${GLOBAL.API_URL}/setInvoiceApprover`,
      approver,
    );
    return response.data.message;
  } catch (error) {
    console.error('Error setting invoice approver:', error);
    throw new Error('Failed to set invoice approver.');
  }
};

const assignInvoiceApprover = async (data) => {
  try {
    const response = await httpClient.post(
      `${GLOBAL.API_URL}/assignInvoiceApprover`,
      data,
    );
    return response.data.message;
  } catch (error) {
    console.error('Error assigning invoice approver:', error);
    throw new Error('Failed to assign invoice approver.');
  }
};

const processInvoiceApproval = async (data) => {
  try {
    const response = await httpClient.post(
      `${GLOBAL.API_URL}/processInvoiceApproval`,
      data,
    );
    return response.data.message;
  } catch (error) {
    console.error('Error processing invoice approval:', error);
    throw new Error(error);
  }
};

const Api = {
  getInvoiceAprovers,
  setInvoiceApprover,
  assignInvoiceApprover,
  processInvoiceApproval,
};

export default Api;
