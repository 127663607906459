import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Container, Loader, Stack, Text } from '@mantine/core';

import PurchaseOrderCard from '../components/PurchaseOrderCard';
import PurchaseOrderLines from '../components/PurchaseOrderLines';
import usePurchaseOrderDetails from '../hooks/usePurchaseOrderDetails';

const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

function PurchaseOrder() {
  const [poNum, setPoNum] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [poLines, setPoLines] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [searchParams] = useSearchParams();
  const [poData, poLoadError, poLoading] = usePurchaseOrderDetails(
    poNum,
    accessToken,
  );

  /**
   * Track and extract query params.
   */
  useEffect(() => {
    const { poNumber, token } = Object.fromEntries([...searchParams]);

    if (!poNumber || !token) {
      setError('Invalid purchase order number or token expired.');
    } else {
      setError(null); // Reset error
      setPoNum(poNumber);
      setAccessToken(token);
    }
  }, [searchParams]);

  /**
   * Intermediate loading flag
   *
   * When user clicks Purchase Order link in the approval mail,
   * this component first parses the query params from the url.
   *
   * During this phase there will be no error, no poLoading flag,
   * and no purchase order data as well. This will result in
   * rendering primary UI without data.
   *
   * The isLoading state has been introduced to handle this as
   * an intermediate flag.
   */
  useEffect(() => {
    setIsLoading(poLoading);
  }, [poLoading]);

  /**
   * Track and set PO data loading error.
   */
  useEffect(() => {
    setError(null);

    if (poLoadError) {
      setError(poLoadError.message);
    }
  }, [poLoadError]);

  /**
   * Construct purchase order lines when PO data is available.
   */
  useEffect(() => {
    const poLines = (poData?.PurchaseOrders?.Lines || []).map(
      (poLine, index) => {
        return {
          ...poLine,
          id: index,
        };
      },
    );

    setPoLines(poLines);
  }, [poData]);

  if (error) {
    return (
      <div>
        <Container style={containerStyle} px="xs">
          <Stack align="center">
            <PlatformLogo />
            <Text size={'sm'} color="red">
              {error}
            </Text>
          </Stack>
        </Container>
      </div>
    );
  }

  if (isLoading) {
    return (
      <Container style={containerStyle} px="xs">
        <Loader color="cyan" />
      </Container>
    );
  }

  return (
    <Container style={containerStyle} px="xs">
      <Stack align="center">
        <PlatformLogo />

        {/* Purchase Order Card */}
        <PurchaseOrderCard
          poNumber={poNum}
          {...poData?.PurchaseOrders}></PurchaseOrderCard>

        {/* Purchase Order Lines */}
        <PurchaseOrderLines
          style={{ minHeight: 100, width: '100%' }}
          poLines={poLines}></PurchaseOrderLines>
      </Stack>
    </Container>
  );
}

const PlatformLogo = () => {
  return (
    <img
      src="https://new.proteacoin.link/static/media/Juice_Primary_Logo.e958107328f60c9a5925.png"
      height={72}
      alt=""
    />
  );
};

export default PurchaseOrder;
