import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Layout from './layouts/Layout';
import AdminLayout from './layouts/AdminLayout';
import Login from './pages/Login';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ApproveInvoice from './pages/ApproveInvoice';
import PurchaseOrder from './pages/PurchaseOrder';
import PrivateRoutes from './components/PrivateRoutes';

// import '@progress/kendo-theme-default/dist/all.css';

// Create a Query Client
const queryClient = new QueryClient();

export default function App() {
  const [isLightMode, setIsLightMode] = useState(true);

  const setTheme = () => {
    setIsLightMode(!isLightMode);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider theme={{ colorScheme: isLightMode ? 'light' : 'dark' }}>
        <ModalsProvider>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route path="/" element={<Layout setTheme={setTheme} />} exact />
              <Route
                path="/admin"
                element={<AdminLayout setTheme={setTheme} />}
              />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/approve-invoice" element={<ApproveInvoice />} />
            <Route path="/purchase-order" element={<PurchaseOrder />} />

            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </ModalsProvider>
      </MantineProvider>
    </QueryClientProvider>
  );
}
