import { useEffect, useState, useCallback } from 'react';
import { Loader, Center, Text } from '@mantine/core';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import filter from '@inovua/reactdatagrid-community/filter';
import '@inovua/reactdatagrid-community/index.css';

import InvoiceApproverAdapter from '../adapters/invoiceApproversAdapter';

const gridStyle = { minHeight: 550 };

const gridColumns = [
  { name: 'email', header: 'Email', defaultFlex: 1, minWidth: 100 },
  { name: 'name', header: 'Name', defaultFlex: 1, minWidth: 100 },
  {
    name: 'approvalLimit',
    header: 'Approval Limit',
    defaultFlex: 1,
    minWidth: 100,
    render: ({ value }) =>
      value ? new Intl.NumberFormat().format(value) : value,
  },
  { name: 'department', header: 'Department', defaultFlex: 1, minWidth: 100 },
  // { name: 'active', header: 'Active', defaultFlex: 1, minWidth: 100, render: (value) => value ? 'Yes' : 'No' },
];

// https://reactdatagrid.io/docs/filtering#filter-types-&-operators
const defaultFilterValue = [
  { name: 'email', operator: 'contains', type: 'string', value: '' },
  { name: 'name', operator: 'startsWith', type: 'string', value: '' },
  { name: 'approvalLimit', operator: 'gte', type: 'number' },
  { name: 'department', operator: 'startsWith', type: 'string', value: '' },
];

function InvoiceApproversList() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [filterValue, setFilterValue] = useState(defaultFilterValue);

  useEffect(() => {
    getApproversList();
  }, []);

  const getApproversList = async () => {
    try {
      const approvers = await InvoiceApproverAdapter.getInvoiceAprovers();
      setData(approvers);
      setOriginalData(approvers);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const onFilterValueChange = useCallback(
    (filterValue) => {
      setFilterValue(filterValue);

      if (filterValue.every((f) => f.value === '')) {
        // If all filter values are empty, restore the original data
        setData(originalData);
      } else {
        // Apply filter to the original data
        const filteredData = filter(originalData, filterValue);
        setData(filteredData);
      }
    },
    [originalData],
  );

  // const onEditComplete = useCallback(({ value, columnId, rowId }) => {
  //   const data = [...data];

  //   data[rowId][columnId] = value;

  //   setData(data);
  //   // setOriginalData(data);
  // }, [data]);

  if (loading) {
    return (
      <Center style={{ height: '100vh' }}>
        <Loader color="cyan" />
      </Center>
    );
  }

  if (error) {
    return (
      <Center style={{ height: '100vh' }}>
        <Text color="red">{error}</Text>
      </Center>
    );
  }

  return (
    <ReactDataGrid
      idProperty="_id"
      style={gridStyle}
      onFilterValueChange={onFilterValueChange}
      filterValue={filterValue}
      // onEditComplete={onEditComplete}
      // editable={true}
      columns={gridColumns}
      dataSource={data}
      pagination={true}
      defaultLimit={10}
      pageSizes={[5, 10, 15, 20]}
    />
  );
}

export default InvoiceApproversList;
