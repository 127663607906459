import {
  Menu,
  Text,
  UnstyledButton,
  Group,
  Box,
  useMantineTheme,
  rem,
} from '@mantine/core';
import {
  IconSettings,
  IconTrash,
  IconChevronRight,
  IconChevronLeft,
  IconLock,
  IconLogout,
  IconSun,
} from '@tabler/icons-react';

export function UserMenu({ setTheme }) {
  const theme = useMantineTheme();

  const handleLogOut = () => {
    window.sessionStorage.clear();
    window.location.href = './';
    localStorage.clear();
  };

  const handleAdminView = () => {
    window.location.href = './admin';
  };

  return (
    <Menu shadow="md" width={200} position="right">
      <Menu.Target>
        <Box
          sx={{
            paddingTop: theme.spacing.sm,
            borderTop: `${rem(1)} solid ${
              theme.colorScheme === 'dark'
                ? theme.colors.dark[4]
                : theme.colors.gray[2]
            }`,
          }}>
          <UnstyledButton
            sx={{
              display: 'block',
              width: '100%',
              padding: theme.spacing.xs,
              borderRadius: theme.radius.sm,
              color:
                theme.colorScheme === 'dark'
                  ? theme.colors.dark[0]
                  : theme.black,

              '&:hover': {
                backgroundColor:
                  theme.colorScheme === 'dark'
                    ? theme.colors.dark[6]
                    : theme.colors.gray[0],
              },
            }}>
            <Group>
              <Box sx={{ flex: 1 }}>
                <Text size="sm" weight={500}>
                  {window.sessionStorage.getItem('userName')}
                </Text>
                <Text color="dimmed" size="xs">
                  {window.sessionStorage.getItem('userEmail')}
                </Text>
              </Box>

              {theme.dir === 'ltr' ? (
                <IconChevronRight size={rem(18)} />
              ) : (
                <IconChevronLeft size={rem(18)} />
              )}
            </Group>
          </UnstyledButton>
        </Box>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Application</Menu.Label>
        <Menu.Item icon={<IconSettings size={14} />} onClick={handleAdminView}>
          Settings
        </Menu.Item>
        <Menu.Item icon={<IconLogout size={14} />} onClick={handleLogOut}>
          Log Out
        </Menu.Item>
        <Menu.Item icon={<IconSun size={14} />} onClick={setTheme}>
          Switch Theme
        </Menu.Item>
        <Menu.Divider />
        <Menu.Label>Danger zone</Menu.Label>
        <Menu.Item color="red" icon={<IconLock size={14} />}>
          Reset my Password
        </Menu.Item>
        <Menu.Item disabled color="red" icon={<IconTrash size={14} />}>
          Delete my account
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
